import { Search } from "@material-ui/icons";
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ExpertSlider from "./Components/ExpertSlider";
import HomePageJobs from "./Components/HomePageJobs";
import MoreServices from "./Components/MoreServices";
import PopularServiceSlider from "./Components/PopularServiceSlider";
import ServiceSlider from "./Components/ServiceSlider";
import TopSection from "./Components/TopSection";
import VerifiedServiceSlider from "./Components/VerifiedServiceSlider";

function MainHome() {
  const navigate = useNavigate();
  const [filterBtn, setFilterBtn] = useState("");

  return (
    // <Box sx={{ backgroundColor: "#F2F3F5" }}>
    <Box sx={{ backgroundColor: "#ffffff" }}>
      {/* <Container> */}
      <TopSection />
      {/* <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"center"}
        sx={{ width: "100%", paddingY: "15px", paddingX: "15px" }}
      >
        <OutlinedInput
          placeholder="Search..."
          sx={{ width: { xs: "100%", md: "50%" } }}
          endAdornment={
            <InputAdornment>
              <IconButton size="small" sx={{ marginTop: "5px" }}>
                <Search />
              </IconButton>
            </InputAdornment>
          }
        />
      </Stack> */}
      <Container>
        <Stack sx={{ marginTop: "20px" }}>
          <Stack
            direction="row"
            justifyContent={"center"}
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Stack
              direction="row"
              justifyContent={"left"}
              alignItems="center"
              sx={{
                width: { xs: "100%", md: "100%" },
                marginTop: "0px",
                marginBottom: "10px",
                flexWrap: "wrap",
              }}
              spacing={1}
            >
              {/* <Button
              variant={filterBtn == "Region" ? "contained" : "outlined"}
              size="small"
              color="success"
              sx={{
                fontSize: "10pt",
                textTransform: "capitalize",
                borderRadius: "30px",
                width: "90px",
                marginBottom: "5px !important",
              }}
              onClick={() => {
                setFilterBtn("Region");
              }}
            >
              Region
            </Button>
            <Button
              variant={filterBtn == "Language" ? "contained" : "outlined"}
              size="small"
              color="success"
              sx={{
                fontSize: "10pt",
                textTransform: "capitalize",
                borderRadius: "30px",
                width: "90px",
                marginBottom: "5px !important",
              }}
              onClick={() => {
                setFilterBtn("Language");
              }}
            >
              Language
            </Button> */}
              <Button
                variant={filterBtn == "Service" ? "contained" : "outlined"}
                size="small"
                color="success"
                sx={{
                  fontSize: "10pt",
                  textTransform: "capitalize",
                  borderRadius: "30px",
                  width: "90px",
                  marginBottom: "5px !important",
                }}
                onClick={() => {
                  // setFilterBtn("Service");
                  navigate("/servicelist");
                }}
              >
                Service
              </Button>
              <Button
                variant={filterBtn == "Job" ? "contained" : "outlined"}
                size="small"
                color="success"
                sx={{
                  fontSize: "10pt",
                  textTransform: "capitalize",
                  borderRadius: "30px",
                  width: "90px",
                  marginBottom: "5px !important",
                }}
                onClick={() => {
                  // setFilterBtn("Job");
                  navigate("/joblist");
                }}
              >
                Job
              </Button>
              <Button
                variant={filterBtn == "Expert" ? "contained" : "outlined"}
                size="small"
                color="success"
                sx={{
                  fontSize: "10pt",
                  textTransform: "capitalize",
                  borderRadius: "30px",
                  width: "90px",
                  marginBottom: "5px !important",
                }}
                onClick={() => {
                  // setFilterBtn("Expert");
                  navigate("/expertlist");
                }}
              >
                Expert
              </Button>

              <Button
                variant={filterBtn == "More" ? "contained" : "outlined"}
                size="small"
                color="success"
                sx={{
                  fontSize: "10pt",
                  textTransform: "capitalize",
                  borderRadius: "30px",
                  width: "90px",
                  marginBottom: "5px !important",
                }}
                onClick={() => {
                  // setFilterBtn("More");
                  navigate("/portfoliolist");
                }}
              >
                More
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <ServiceSlider all="See more..." type="Services" route={"servicelist"} />
      <ExpertSlider all="See more..." type="Experts" route={"expertlist"} />
      <HomePageJobs />

      <PopularServiceSlider
        all="See more..."
        type="Most Popular services"
        route={"servicelist"}
      />
      <VerifiedServiceSlider
        all="See more..."
        type="Verified pro services"
        route={"servicelist"}
      />
      <MoreServices
        all="See more..."
        type="More Services"
        route={"servicelist"}
      />

      {/* </Container> */}
    </Box>
  );
}

export default MainHome;
