import React from "react";
export const decodeLocalData = (name) => {
  let result = atob(window.localStorage.getItem(name));
  return JSON.parse(result);
};
const LocalDataMethod = () => {
  return <div></div>;
};

export default LocalDataMethod;
