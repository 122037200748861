import { Button, Modal, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { userTypeContext } from "../../../../App";
const decodeLocalData = (name) => {
  let result = atob(window.localStorage.getItem(name));
  return JSON.parse(result);
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", md: 600 },
  height: "auto",
  backgroundColor: "white",
  boxShadow: 24,
  borderRadius: "8px",
  boxSizing: "border-box",
  p: 4,
};
const DeleteConfirmModal = ({ open, handleClose, jobId, jobRefer, poid }) => {
  const navigate = useNavigate();
  let localData = decodeLocalData("gh_sl@g");
  const [userTypeInfo, setUserTypeInfo] = useContext(userTypeContext);

  const handleDeleteJob = () => {
    const formData = new FormData();
    formData.append("gid", jobId);
    formData.append("refer", jobRefer);
    formData.append("poid", poid);
    formData.append("poster", userTypeInfo?.poster);
    formData.append("cRefer", userTypeInfo?.c_refer);
    fetch("https://ghorami.com/profile/login/api_form/job_delete.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        handleClose();
        navigate("/myJobPosts");
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  };
  return (
    <Box>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="p" sx={{ fontSize: "16pt", fontWeight: 700 }}>
              Delete Confirmation
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ marginTop: "5px" }}
          >
            <Typography variant="p" sx={{ fontSize: "15pt" }}>
              Are you sure to delete this job?
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ marginTop: "30px" }}
          >
            <Button
              variant="contained"
              sx={{
                // marginTop: "15px",
                width: "40%",
                borderRadius: "30px",
                backgroundColor: "gray",
                fontSize: { xs: "8pt", md: "10pt" },
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#14506A",
                },
              }}
              size="small"
              onClick={handleClose}
            >
              Cancle
            </Button>
            <Button
              variant="contained"
              sx={{
                // marginTop: "15px",
                width: "40%",
                borderRadius: "30px",
                backgroundColor: "#22AB7D",
                fontSize: { xs: "8pt", md: "10pt" },
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#14506A",
                },
              }}
              size="small"
              onClick={handleDeleteJob}
            >
              Confirm
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default DeleteConfirmModal;
