import {
  ArrowForwardIos,
  CheckCircle,
  Favorite,
  Flag,
  HelpRounded,
  LocalOffer,
  LocationOn,
  OpenInNew,
  Psychology,
  Remove,
  Schedule,
  TurnedIn,
  WatchLater,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Rating,
  Snackbar,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { jobDetailsApi } from "../API/Api";
import "./JobDetailsModal.css";
import { decodeLocalData } from "../Local Data/LocalDataMethod";
import InviteModal from "./Job proposal/Job details/InviteModal";
import DeleteConfirmModal from "./Job proposal/Job details/DeleteConfirmModal";

const style = {
  position: "fixed",
  top: "10.5%",
  right: "0%",
  maxWidth: "0px",
  maxHeight: "90vh",
  backgroundColor: "background.paper",
  boxShadow: 1,
  overflow: "hidden",
  overflowY: "scroll",
  zIndex: 5,
  transition: "all .5s",
};
const JobDetailsModal = (props) => {
  const navigate = useNavigate();
  const { test, handleTest, fetchJobDetails } = props;
  let localData = decodeLocalData("gh_sl@g");
  const [inviteOpen, setInviteOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleInviteOpen = () => {
    setInviteOpen(true);
  };
  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleInviteClose = () => setInviteOpen(false);
  const handleDeleteClose = () => setDeleteOpen(false);

  // useEffect(() => {
  //   const formData = new FormData();
  //   formData.append("gid", jobId);
  //   formData.append("refer", jobRefer);
  //   formData.append("poid", poid);

  //   fetch(jobDetailsApi, {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setFetchJobDetails(result[0]);

  //     })
  //     .catch((error) => console.log("JobDetailsApiError=> ", error));
  // }, [jobId, jobRefer]);
  const [jobUrl, setJobUrl] = useState("");
  useEffect(() => {
    setJobUrl(
      "https://user.ghorami.com/JobDetails/" +
        fetchJobDetails?.[0]?.gid +
        "/" +
        fetchJobDetails?.[0]?.job_refer +
        "/" +
        fetchJobDetails?.[0]?.job_poster
    );
  }, []);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackOpen = (message) => {
    setSnackOpen(true);
    setSnackMessage(message);
  };
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };
  return (
    <Paper style={style} className={test ? "showModal" : ""}>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent="right"
        alignItems={"center"}
        sx={{ marginTop: "20px", marginRight: "20px" }}
      >
        <OpenInNew
          sx={{
            fontSize: "12pt",
          }}
        />
        <Link
          to={
            "/JobDetails/" +
            fetchJobDetails?.[0]?.gid +
            "/" +
            fetchJobDetails?.[0]?.job_refer +
            "/" +
            fetchJobDetails?.[0]?.job_poster
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography
            variant="p"
            sx={{
              fontSize: "9pt",
              fontWeight: 600,
            }}
          >
            Open in a new tab
          </Typography>
        </Link>
      </Stack>
      <Box
        sx={{
          position: "relative",
          marginTop: "20px",
          paddingBottom: "20px",
          marginX: "20px",
        }}
      >
        <DeleteConfirmModal
          handleClose={handleDeleteClose}
          open={deleteOpen}
          jobId={fetchJobDetails?.[0]?.gid}
          jobRefer={fetchJobDetails?.[0]?.job_refer}
          poid={fetchJobDetails?.[0]?.job_poster}
        />
        <Grid
          container
          spacing={0}
          sx={{
            border: "1px solid #d5d3d3",
            borderRadius: "12px",
          }}
        >
          <Grid
            item
            xs={12}
            md={8}
            sx={{ borderRight: { xs: "none", md: "1px solid #d5d3d3" } }}
          >
            <Stack sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}>
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                {fetchJobDetails?.[0]?.title}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  marginTop: "30px",
                  fontSize: "9pt",
                  fontWeight: 500,
                  color: "#0c87cf",
                }}
              >
                {fetchJobDetails?.[0]?.category?.[0]?.title}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                posted at {fetchJobDetails?.[0]?.post_time?.split(" ")[1]} on{" "}
                {fetchJobDetails?.[0]?.post_time?.split(" ")[0]}
              </Typography>
              <Stack
                direction={"row"}
                spacing={0.5}
                justifyContent="left"
                alignItems={"center"}
                sx={{
                  marginTop: "10px",
                }}
              >
                <LocationOn
                  sx={{
                    fontSize: "6pt",
                    fontWeight: 500,
                    color: "#0c87cf",
                  }}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "9pt",
                    fontWeight: 500,
                  }}
                >
                  {fetchJobDetails?.[0]?.poster_info?.Location}
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}>
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 500,
                }}
              >
                {fetchJobDetails?.[0]?.details}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              spacing={0}
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}
            >
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="left"
                alignItems={"flex-start"}
                sx={{ width: "16%" }}
              >
                <LocalOffer
                  sx={{
                    fontSize: "10pt",
                    fontWeight: 500,
                    transform: "scaleX(-1)",
                  }}
                />
                <Stack justifyContent="baseline" alignItems={"flex-start"}>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                      lineHeight: "8pt",
                    }}
                  >
                    {fetchJobDetails?.[0]?.budget}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 500,
                      color: "gray",
                      lineHeight: "9pt",
                    }}
                  >
                    {fetchJobDetails?.[0]?.budget_type}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="left"
                alignItems={"flex-start"}
                sx={{ width: "34%" }}
              >
                <Schedule
                  sx={{
                    fontSize: "10pt",
                    fontWeight: 500,
                  }}
                />
                <Stack justifyContent="baseline" alignItems={"flex-start"}>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                      lineHeight: "8pt",
                    }}
                  >
                    {fetchJobDetails?.[0]?.jobSchedule?.[0]?.title}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 500,
                      color: "gray",
                      lineHeight: "9pt",
                    }}
                  >
                    Project Length
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="left"
                alignItems={"flex-start"}
                sx={{ width: "43%" }}
              >
                <Psychology
                  sx={{
                    fontSize: "12pt",
                    fontWeight: 500,
                    transform: "scaleX(-1)",
                  }}
                />
                <Stack justifyContent="baseline" alignItems={"flex-start"}>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                      lineHeight: "8pt",
                    }}
                  >
                    {fetchJobDetails?.[0]?.expetLevel?.[0]?.title}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 500,
                      color: "gray",
                      lineHeight: "9pt",
                    }}
                  >
                    {fetchJobDetails?.[0]?.Freelancer?.[0]?.title}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}>
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 600,
                }}
              >
                Project Type :
                <span
                  style={{
                    fontSize: "9pt",
                    fontWeight: 500,
                  }}
                >
                  {" "}
                  {fetchJobDetails?.[0]?.jobState?.[0]?.title}
                </span>
              </Typography>
            </Stack>
            <Stack sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}>
              {/* <Stack>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "9pt",
                    fontWeight: 600,
                  }}
                >
                  Skills and Expertise
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={1}
                  justifyContent="left"
                  alignItems={"center"}
                  flexWrap={"warp"}
                  mt={2}
                >
                  {fetchJobDetails?.skill.split(", ").map((element) => (
                    <Button
                      size="small"
                      variant="container"
                      sx={{
                        padding: "1px",
                        backgroundColor: "#d5dfed",
                        fontSize: "8pt",
                        textTransform: "capitalize",
                        borderRadius: "30px",
                      }}
                    >
                      {element}
                    </Button>
                  ))}
                </Stack>
              </Stack> */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Stack>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "9pt",
                        fontWeight: 600,
                      }}
                    >
                      Skill and Expertise
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent="left"
                      alignItems={"center"}
                      flexWrap={"warp"}
                      // mt={2}
                    >
                      {fetchJobDetails?.[0]?.skill &&
                        JSON.parse(fetchJobDetails?.[0]?.skill)?.map(
                          (element) => (
                            <Button
                              size="small"
                              variant="container"
                              sx={{
                                paddingY: "1px",
                                paddingX: "15px",
                                backgroundColor: "#d5dfed",
                                fontSize: "8pt",
                                textTransform: "capitalize",
                                borderRadius: "30px",
                              }}
                            >
                              {element}
                            </Button>
                          )
                        )}
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "9pt",
                        fontWeight: 600,
                      }}
                    >
                      Working Limit
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "8pt",
                        fontWeight: 500,
                        color: "gray",
                        lineHeight: "9pt",
                      }}
                    >
                      {fetchJobDetails?.[0]?.jobHour?.[0]?.title}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <Stack sx={{ padding: "15px" }}>
              <Stack>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "9pt",
                    fontWeight: 600,
                  }}
                >
                  Activity on this job
                </Typography>
                <Stack
                  //   spacing={1}
                  justifyContent="left"
                  alignItems={"flex-start"}
                  sx={{ marginTop: "10px" }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 600,
                    }}
                  >
                    Proposal :
                    <span
                      style={{
                        fontSize: "9pt",
                        fontWeight: 500,
                      }}
                    >
                      {" "}
                      {fetchJobDetails?.[0]?.total_proposal}
                    </span>
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 600,
                    }}
                  >
                    Interviewing :
                    <span
                      style={{
                        fontSize: "9pt",
                        fontWeight: 500,
                      }}
                    >
                      {" "}
                      {fetchJobDetails?.[0]?.total_interview}
                    </span>
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 600,
                    }}
                  >
                    Invites Sent :
                    <span
                      style={{
                        fontSize: "9pt",
                        fontWeight: 500,
                      }}
                    >
                      {" "}
                      {fetchJobDetails?.[0]?.total_invite}
                    </span>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} sx={{}}>
            <Stack
              spacing={2}
              sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}
            >
              {/* <Button
                size="small"
                variant="container"
                sx={{
                  padding: "5px",
                  backgroundColor: "#22AB7D",
                  color: "white",
                  fontSize: "9pt",
                  fontWeight: 700,
                  textTransform: "capitalize",
                  borderRadius: "30px",
                }}
                onClick={() => {
                  navigate(
                    "/newProposal/" + jobId + "/" + jobRefer + "/" + poid
                  );
                }}
              >
                Submit a Proposal
              </Button>
              <Button
                size="small"
                variant="outlined"
                startIcon={<Favorite color="black" size="small" />}
                sx={{
                  padding: "5px",
                  border: "1px solid #22AB7D",
                  color: "black",
                  fontSize: "9pt",
                  fontWeight: 700,
                  textTransform: "capitalize",
                  borderRadius: "30px",
                }}
                href={"https://ghorami.com/profile/login/login.php"}
                target="_blank"
              >
                Save Job
              </Button> */}
              {fetchJobDetails?.[0]?.poster != localData?.sopnoid &&
              fetchJobDetails?.[0]?.userAppliState?.[0]?.States == 0 ? (
                <>
                  <Button
                    size="small"
                    variant="container"
                    sx={{
                      padding: "5px",
                      backgroundColor: "#22AB7D",
                      color: "white",
                      fontSize: "9pt",
                      fontWeight: 700,
                      textTransform: "capitalize",
                      borderRadius: "30px",
                      "&:hover": {
                        color: "black",
                      },
                    }}
                    onClick={() => {
                      navigate(
                        "/newProposal/" +
                          fetchJobDetails?.[0]?.gid +
                          "/" +
                          fetchJobDetails?.[0]?.job_refer +
                          "/" +
                          fetchJobDetails?.[0]?.job_poster
                      );
                    }}
                  >
                    Submit a Proposal
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<Favorite color="black" size="small" />}
                    sx={{
                      padding: "5px",
                      border: "1px solid #22AB7D",
                      color: "black",
                      fontSize: "9pt",
                      fontWeight: 700,
                      textTransform: "capitalize",
                      borderRadius: "30px",
                    }}
                    onClick={() => {
                      navigate("");
                    }}
                    // href={"https://ghorami.com/profile/login/login.php"}
                    // target="_blank"
                  >
                    Save Job
                  </Button>
                  {/* <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent={"left"}
                    alignItems={"center"}
                  >
                    <Flag
                      sx={{
                        fontSize: "11pt",
                        fontWeight: 500,
                        color: "black",
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "9pt",
                        fontWeight: 500,
                        color: "black",
                      }}
                      onClick={() => {
                        navigate("/flag");
                      }}
                    >
                      Flag as inappropriate
                    </Typography>
                  </Stack> */}
                </>
              ) : (
                <>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent={"left"}
                    alignItems={"center"}
                  >
                    <TurnedIn
                      sx={{
                        fontSize: "11pt",
                        fontWeight: 500,
                        color: "black",
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "9pt",
                        fontWeight: 500,
                        color: "black",
                      }}
                    >
                      Already submitted a proposal
                    </Typography>
                  </Stack>
                </>
              )}
              {fetchJobDetails?.[0]?.poster == localData?.sopnoid && (
                <>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"space-between"}
                    sx={{ flexWrap: "wrap" }}
                  >
                    <Button
                      size="small"
                      variant="container"
                      sx={{
                        width: "47%",
                        padding: "5px",
                        backgroundColor: "#22AB7D",
                        color: "white",
                        fontSize: "9pt",
                        fontWeight: 700,
                        textTransform: "capitalize",
                        borderRadius: "30px",
                        margin: "3px",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                      onClick={() => {
                        navigate(
                          "/updatejobinfo/" +
                            fetchJobDetails?.[0]?.gid +
                            "/" +
                            fetchJobDetails?.[0]?.job_refer +
                            "/" +
                            fetchJobDetails?.[0]?.job_poster
                        );
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{
                        width: "47%",
                        padding: "5px",
                        border: "1px solid #22AB7D",
                        color: "black",
                        fontSize: "9pt",
                        fontWeight: 700,
                        textTransform: "capitalize",
                        margin: "3px",
                        borderRadius: "30px",
                      }}
                      onClick={handleDeleteOpen}
                    >
                      Delete
                    </Button>
                  </Stack>

                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      padding: "5px",
                      border: "1px solid #22AB7D",
                      color: "black",
                      fontSize: "9pt",
                      fontWeight: 700,
                      textTransform: "capitalize",
                      // margin: "3px",
                      borderRadius: "30px",
                    }}
                    onClick={() => {
                      handleInviteOpen();
                    }}
                  >
                    Invite
                  </Button>
                  {/* <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      padding: "5px",
                      border: "1px solid #22AB7D",
                      color: "black",
                      fontSize: "9pt",
                      fontWeight: 700,
                      textTransform: "capitalize",
                      borderRadius: "30px",
                    }}
                    onClick={() => {
                      // handleNewOfferOpen();
                    }}
                  >
                    New offer
                  </Button> */}
                </>
              )}

              <Stack
                spacing={1}
                direction={"row"}
                justifyContent={"left"}
                alignItems={"center"}
              >
                <Flag
                  sx={{
                    fontSize: "11pt",
                    fontWeight: 500,
                    color: "black",
                  }}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "9pt",
                    fontWeight: 500,
                    color: "black",
                  }}
                  onClick={() => {
                    navigate("/flag");
                  }}
                >
                  Flag as inappropriate
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "8pt",
                    fontWeight: 400,
                    color: "black",
                  }}
                >
                  Send a proposal for: {fetchJobDetails?.[0]?.connect_need}{" "}
                  connects
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "8pt",
                    fontWeight: 400,
                    color: "black",
                  }}
                >
                  Available Connects :{" "}
                  {
                    fetchJobDetails?.[0]?.connect_available?.[0]
                      ?.availableConect
                  }{" "}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              spacing={1}
              sx={{ padding: "15px", borderBottom: "1px solid #d5d3d3" }}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "9pt",
                  fontWeight: 600,
                }}
              >
                About the client
              </Typography>

              {fetchJobDetails?.[0]?.user_state?.[0]?.pay_veri == 1 ? (
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                  sx={{ paddingTop: "10px" }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 400,
                      color: "black",
                    }}
                  >
                    Payment Method verified
                  </Typography>
                  <HelpRounded
                    sx={{
                      fontSize: "10pt",
                      fontWeight: 400,
                      color: "darkgreen",
                    }}
                  />
                </Stack>
              ) : (
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                  sx={{ paddingTop: "10px" }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "8pt",
                      fontWeight: 400,
                      color: "black",
                    }}
                  >
                    Payment Method not verified
                  </Typography>
                  <HelpRounded
                    sx={{
                      fontSize: "10pt",
                      fontWeight: 400,
                      color: "black",
                    }}
                  />
                </Stack>
              )}

              <Stack
                spacing={0}
                direction={"column"}
                justifyContent="left"
                alignItems={"flex-start"}
              >
                <Box sx={{ width: "35px", height: "35px" }}>
                  <Box
                    component="img"
                    sx={{
                      // padding: "0 12px",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      cursor: "pointer",
                      borderRadius: "50%",

                      // display: { xs: "none", sm: "none", md: "block" },
                    }}
                    alt=""
                    src={
                      "//yeapbe.com/" +
                      fetchJobDetails?.[0]?.poster_info?.user_pic?.[0]?.pic_url
                    }
                  />
                </Box>
                <Stack
                  spacing={0.5}
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"center"}
                  sx={{ lineHeight: "20pt" }}
                >
                  {fetchJobDetails?.[0]?.user_state?.[0]?.state == 1 ? (
                    <CheckCircle
                      sx={{
                        color: "darkgreen",
                        fontSize: "8pt",
                        fontWeight: 600,
                      }}
                    />
                  ) : (
                    <CheckCircle
                      sx={{
                        color: "black",
                        fontSize: "8pt",
                        fontWeight: 600,
                      }}
                    />
                  )}
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "11pt",
                      fontWeight: 600,
                      color: "black",
                    }}
                  >
                    {fetchJobDetails?.[0]?.poster_info?.Name}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                spacing={0}
                justifyContent="left"
                alignItems={"flex-start"}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "9pt",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  {fetchJobDetails?.[0]?.poster_info?.country}{" "}
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "8pt",
                    fontWeight: 400,
                    color: "black",
                  }}
                >
                  {fetchJobDetails?.[0]?.poster_info?.Location}{" "}
                  {fetchJobDetails?.[0]?.post_time?.split(" ")[1]}
                </Typography>
              </Stack>
              <Stack
                spacing={0}
                justifyContent="left"
                alignItems={"flex-start"}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "9pt",
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  {fetchJobDetails?.[0]?.poster_total_job} job posted
                </Typography>
                <Stack>
                  <Rating
                    name="read-only"
                    value={parseFloat(
                      fetchJobDetails?.[0]?.user_rate?.average_rating
                    )}
                    readOnly
                    size="small"
                  />
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "9pt",
                      fontWeight: 500,
                      color: "black",
                    }}
                  >
                    ({fetchJobDetails?.[0]?.user_rate?.total_review} reviews)
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack sx={{ padding: "15px" }}>
              <Stack>
                <Stack
                  //   spacing={1}
                  justifyContent="left"
                  alignItems={"flex-start"}
                  sx={{ marginTop: "10px" }}
                >
                  <Button
                    size="small"
                    variant="container"
                    sx={{
                      paddingY: "2px",
                      paddingX: "15px",
                      backgroundColor: "#d5dfed",
                      fontSize: "8pt",
                      textTransform: "capitalize",
                      borderRadius: "30px",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        "https://user.ghorami.com/JobDetails/" +
                          fetchJobDetails?.[0]?.gid +
                          "/" +
                          fetchJobDetails?.[0]?.job_refer +
                          "/" +
                          fetchJobDetails?.[0]?.job_poster
                      );
                      handleSnackOpen("Job Link Copied to Clipboard");
                    }}
                  >
                    Share this job
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <IconButton
          aria-label="delete"
          sx={{ position: "absolute", left: "-10px", top: "-50px" }}
          size="small"
        >
          <ArrowForwardIos
            onClick={() => {
              handleTest();
            }}
          />
        </IconButton>
      </Box>
      <InviteModal
        handleClose={handleInviteClose}
        open={inviteOpen}
        jobRefer={fetchJobDetails?.job_refer}
        job_poster={fetchJobDetails?.job_poster}
      />
    </Paper>
  );
};

export default JobDetailsModal;
