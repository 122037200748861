import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  OutlinedInput,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import ghorami from "../../../../images/ghorami.ico";
import "../../../Independent Components/NewService Components/css/NewServiceModal.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", md: 600 },
  height: "auto",
  backgroundColor: "background.paper",
  //   padding: "20px",
  boxShadow: 24,
  boxSizing: "border-box",

  //
};
const initialField = {
  title: "",
  price: "",
  message: "",
  workUrl: "",
  attachment: "",
};

const decodeLocalData = (name) => {
  let result = atob(window.localStorage.getItem(name));
  return JSON.parse(result);
};
const InviteModal = ({ open, handleClose, jobRefer, job_poster }) => {
  const [title, setTitle] = useState("");
  const [workUrl, setWorkUrl] = useState("");
  const [message, setmessage] = useState("");
  let localData = decodeLocalData("gh_sl@g");
  const userName = "Shahriar";

  const [inputField, setInputField] = useState(initialField);
  const [inviteInfo, setInviteInfo] = useState({});

  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "mobile_email") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }

    if (event.target.name === "message") {
      isFieldValid = /[a-z]|[A-Z]|[^\x00-\x7F]+/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newinviteInfo = inviteInfo;
      newinviteInfo[event.target.name] = event.target.value;
      setInviteInfo({ ...newinviteInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
      setInviteInfo(newField);
    }
  };
  const handleSend = (value) => {
    const formData = new FormData();
    formData.append("memailid", inviteInfo?.mobile_email);
    formData.append("memessage", inviteInfo?.message);
    formData.append("userId", localData?.sopnoid);
    formData.append("invitation_type", "JobDetails");
    formData.append("invitor_name", userName);
    formData.append("poster", job_poster);
    formData.append("inJob_id", jobRefer);

    fetch("https://ghorami.com/profile/ac_invite/job_invite.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        // setSubCategoryListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="" sx={style}>
          <Box
            sx={{
              backgroundColor: "white",
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            <Box sx={{ height: "60px" }}></Box>
            <Stack
              spacing={2}
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              sx={{
                paddingX: "10px",
                height: "60px",
                width: "100%",
                position: "fixed",
                top: 0,
                boxSizing: "border-box",
                zIndex: 100,
              }}
            >
              <Stack
                spacing={2}
                direction={"row"}
                alignItems="center"
                justifyContent={"left"}
              >
                <Box component={"img"} src={ghorami} sx={{ width: "55px" }} />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "14pt",
                    fontWeight: 600,
                  }}
                >
                  Invite an Expert
                </Typography>
              </Stack>
              <IconButton
                aria-label="delete"
                size="medium"
                onClick={() => {
                  handleClose();
                }}
              >
                <HighlightOff sx={{ color: "black" }} />
              </IconButton>
            </Stack>

            <Box
              sx={{
                paddingX: "20px",
                paddingY: "15px",
                backgroundColor: "white",
              }}
            >
              <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12}>
                  <Stack spacing={-0.5}>
                    <Stack direction={"row"} justifyContent="space-between">
                      <Typography
                        variant="p"
                        sx={{ fontSize: "11pt", fontWeight: 500 }}
                      >
                        Mobile or Email
                      </Typography>
                      {inputField?.fieldErrorAlert === "mobile_email" && (
                        <small style={{ color: "#CB0101" }}>
                          {" "}
                          *Enter Mobile No or Email
                        </small>
                      )}
                    </Stack>

                    <OutlinedInput
                      name="mobile_email"
                      type="text"
                      size="small"
                      placeholder="Mobile or email"
                      sx={{ width: "100%" }}
                      onChange={handleChange}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack spacing={0}>
                    <Stack direction={"row"} justifyContent="space-between">
                      <Typography
                        variant="p"
                        sx={{ fontSize: "11pt", fontWeight: 500 }}
                      >
                        Message
                      </Typography>
                      {inputField?.fieldErrorAlert === "message" && (
                        <small style={{ color: "#CB0101" }}>
                          {" "}
                          *Details field is empty!
                        </small>
                      )}
                    </Stack>

                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={2}
                      placeholder="Enter Details"
                      name="message"
                      className="customTextField"
                      style={{
                        fontSize: "11pt",
                        fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                        width: "100%",
                        maxWidth: "100%",
                        boxSizing: "border-box",
                        border: "1px solid #c8c9cb",
                        borderRadius: "4px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        paddingTop: "10px",
                      }}
                      onChange={handleChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    spacing={-0.5}
                    direction="row"
                    alignItems={"center"}
                    justifyContent="left"
                  >
                    {inputField.mobile_email && inputField.message ? (
                      <Button
                        variant="contained"
                        color="success"
                        sx={{ width: "20%" }}
                        onClick={() => {
                          handleSend();
                        }}
                      >
                        Send
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="success"
                        disabled
                        sx={{ width: "20%" }}
                      >
                        Send
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default InviteModal;
