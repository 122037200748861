import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
// import { Spinner } from "react-bootstrap";
import AuthProvider from "./Context/AuthProvider";
import { Box } from "@mui/material";
import loading from "./Components/Vendors/Images/loading.gif";
import { createContext } from "react";
import { useState } from "react";
import Register from "./Components/Authentication/Register/Register";
import Login from "./Components/Authentication/Login/Login";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import MainHome from "./Components/Homepage Components/MainHome/MainHome";
import { lazy } from "react";

const Routing = lazy(() => import("./Components/Routing/Routing.js"));
const Signup = lazy(() => import("./Components/Authentication/SignUp/Signup"));
const ForgetPassword = lazy(() =>
  import("./Components/Authentication/ForgetPass/ForgetPassword")
);

export const UserContext = createContext();
export const userTypeContext = createContext();

function App() {
  const [userData, setUserData] = useState({});
  const [userTypeInfo, setUserTypeInfo] = useState({});

  const decodeLocalData = (name) => {
    let storeData = window.localStorage.getItem(name);
    let result;
    if (storeData) {
      result = atob(storeData);
    } else {
      result = null;
    }
    return result ? JSON.parse(result) : null;
  };

  // useEffect(() => {
  let localData = decodeLocalData("gh_sl@g");
  // }, []);

  return (
    <div>
      <UserContext.Provider value={[userData, setUserData]}>
        <userTypeContext.Provider value={[userTypeInfo, setUserTypeInfo]}>
          <AuthProvider>
            <Suspense
              fallback={
                <Box
                  sx={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* <Spinner animation="border" role="status" variant="success" /> */}
                  <img
                    src={loading}
                    alt="loading"
                    style={{
                      width: "200px",
                      height: "200px",
                    }}
                  />
                </Box>
              }
            >
              <BrowserRouter>
                <Routes>
                  {!localData?.sopnoid && (
                    <Route path="/login" element={<Login />} />
                  )}
                  <Route path="/register" element={<Register />} />
                  {!localData?.sopnoid && (
                    <Route path="/signup" element={<Signup />} />
                  )}
                  {!localData?.sopnoid && (
                    <Route path="/forgetpass" element={<ForgetPassword />} />
                  )}

                  {!localData?.sopnoid && (
                    <Route
                      path="/"
                      element={
                        <PrivateRoute>
                          <MainHome />
                        </PrivateRoute>
                      }
                    ></Route>
                  )}
                </Routes>
                {localData?.sopnoid && <Routing />}
              </BrowserRouter>
            </Suspense>
          </AuthProvider>
        </userTypeContext.Provider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
