import * as React from "react";
import Box from "@mui/material/Box";

import { Button, Container, Divider, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { KeyboardArrowRight } from "@mui/icons-material";
import Slider from "react-slick";
import { useState } from "react";
import { useEffect } from "react";
import "./HomeSlider.css";
import { useNavigate } from "react-router-dom";
import { serviceApi } from "../../../API/Api";
import ServiceCardItem from "../../../Homepage Components/Trending Services/ServiceCardItem";

const settings = {
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  draggable: true,
  dots: false,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const decodeLocalData = (name) => {
  let result = atob(window.localStorage.getItem(name));
  return JSON.parse(result);
};

const MoreServices = (props) => {
  let localData = decodeLocalData("gh_sl@g");
  const { all, type, route } = props;
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(
      "https://ghorami.com/profile/login/api_service/new_24/servi_more_new.php"
    )
      .then((res) => res.json())
      .then((result) => {
        setData(result);
      });

    // fetch(typeApiUrl)
    //   .then((res) => res.json())
    //   .then((result) => {
    //     setBookType(result.slice(20, 28));
    //   });
  }, []);

  useEffect(() => {
    const formData = new FormData();
    // formData.append("pref", sourceValueId);
    formData.append("page", page);

    fetch(
      "https://ghorami.com/profile/login/api_service/new_24/servi_more_new.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setData([...data, ...result]);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, [page]);

  return (
    <Box sx={{ backgroundColor: "#ffffff", paddingY: "15px" }}>
      <Container className="homeSlick">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "0% 0" }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: "13pt" }}>
            {type}
          </Typography>
          <Button
            sx={{
              fontSize: "10pt",
              textTransform: "capitalize",
            }}
            onClick={() => {
              navigate("/" + route);
            }}
          >
            {all}
          </Button>
        </Stack>
        <Divider />
        <Box sx={{ marginTop: "15px" }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {data?.map((data) => (
              <Grid item xs={2} sm={4} md={3} key={data.service_refer}>
                <ServiceCardItem data={data} />
              </Grid>
            ))}
          </Grid>
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            sx={{ marginY: "20px" }}
          >
            <Button
              variant="outlined"
              sx={{
                fontFamily: "Roboto Condensed",
                fontWeight: 500,
                fontSize: "12pt",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={() => {
                // navigate("/servicelist");
                setPage(page + 1);
              }}
            >
              Load more...
            </Button>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default MoreServices;
