import { Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import logo from "../../../../images/topSection.png";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const decodeLocalData = (name) => {
  let result = atob(window.localStorage.getItem(name));
  return JSON.parse(result);
};
const TopSection = () => {
  let localData = decodeLocalData("gh_sl@g");

  const d = new Date();
  let day = weekday[d.getDay()];

  return (
    <Box sx={{ paddingTop: "10px" }}>
      <Container>
        <Box
          sx={{
            border: "1px solid #dbdbdb",
            borderRadius: "12px",
            backgroundColor: "white",
          }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{}}
          >
            <Stack
              alignItems="flex-start"
              justifyContent={"center"}
              sx={{ width: "60%", padding: "15px" }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 500 }}
              >
                {day},{" "}
                {d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear()}
              </Typography>
              <Typography
                variant="p"
                sx={{ fontSize: "16pt", fontWeight: 600 }}
              >
                Hi, {localData?.uname}
              </Typography>
            </Stack>
            <Box
              component={"img"}
              src={logo}
              sx={{ height: "90px", borderRadius: "12px" }}
            />
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default TopSection;
