import { WatchLater } from "@material-ui/icons";
import {
  AccessTime,
  Badge,
  CheckCircleRounded,
  Engineering,
  Favorite,
  LocationOn,
  Psychology,
  Schedule,
  WorkHistory,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/material";
import React from "react";
import { useState } from "react";
import { json, useNavigate } from "react-router-dom";
import JobDetailsModal from "../../JobList Components/JobDetailsModal";
import "./Job.css";
import { jobDetailsApi } from "../../API/Api";
import { decodeLocalData } from "../../Local Data/LocalDataMethod";

const Job = (props) => {
  const navigate = useNavigate();
  const { jobData } = props;
  const {
    job_budget,
    job_title,
    job_category,
    job_currency,
    job_expertlevel,
    job_freelancer,
    job_hour,
    job_id,
    job_info,
    poster_locat,
    job_pay_type,
    job_poster,
    job_poster_pic,
    job_poster_name,
    job_refer,
    job_schedule,
    job_skill,
    job_state,
    job_time,
    job_type,
    job_visibility,
    poster_status,
  } = jobData;
  let skill = [];
  // skill = job_skill && JSON?.parse(job_skill);
  // const skill = json.parse(job_skill.split(", "));
  const user = decodeLocalData("gh_sl@g");
  const [jobId, setJobId] = useState("");
  const [jobRefer, setJobRefer] = useState("");
  const [poid, setPoid] = useState("");
  const [test, setTest] = useState(false);
  const [fetchJobDetails, setFetchJobDetails] = useState();

  const [open, setOpen] = useState(false);

  const handleOpen = (refer, id, poid) => {
    const formData = new FormData();
    formData.append("gid", id);
    formData.append("refer", refer);
    formData.append("poid", poid);
    formData.append("userId", user?.sopnoid);

    // fetch(jobDetailsApi, {
    fetch("https://ghorami.com/profile/login/api_job/new_24/job_details.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setFetchJobDetails(result);
      })
      .catch((error) => console.log("JobDetailsApiError=> ", error));
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleTest = () => {
    setTest(!test);
  };
  return (
    <Paper
      elevation={3}
      onClick={() => {
        handleOpen(job_refer, job_id, job_poster);
        handleTest();
      }}
      sx={{
        padding: "5px",
        mt: "15px",
        cursor: "pointer",
        borderRadius: "12px",
        "&:hover": {
          boxShadow: "5px 5px 15px grey;",
        },
      }}
    >
      <Grid
        container
        spacing={0}
        rowSpacing={1}
        sx={{ padding: { md: "20px 10px" } }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Stack spacing={1} sx={{ width: "90%" }}>
                <Stack
                  spacing={1}
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "14pt", fontWeight: 600 }}
                  >
                    {job_title}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "12pt", fontWeight: 600 }}
                  >
                    {job_currency} {job_budget}
                  </Typography>
                </Stack>
                <Stack
                  spacing={1}
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"left"}
                >
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"left"}
                  >
                    <Psychology style={{ fontSize: "14pt" }} />

                    <Typography
                      variant="p"
                      sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                    >
                      {job_expertlevel}
                    </Typography>
                  </Stack>
                  {/* <Typography
                    variant="p"
                    sx={{ color: "gray", fontSize: "12pt", ml: "4px" }}
                  >
                    {job_currency} {job_budget}
                  </Typography> */}
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"left"}
                  >
                    <WatchLater style={{ fontSize: "13pt" }} />

                    <Typography
                      variant="p"
                      sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                    >
                      {job_time}
                    </Typography>
                  </Stack>
                </Stack>
                <Typography variant="p" sx={{ fontSize: "11pt" }}>
                  {/* {job_info + "..."} */}
                  {job_info && job_info?.slice(0, 300) + "..."}
                </Typography>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {skill?.length > 0 &&
                    skill?.map((element) => (
                      <Button
                        sx={{
                          fontSize: "8pt",
                          color: "white",
                          backgroundColor: "darkgreen",
                          testTransform: "capitalize",
                          paddingX: "10px",
                          paddingY: "0px",
                          borderRadius: "30px",
                          "&:hover": {
                            backgroundColor: "#22AB7D",
                            color: "white",
                          },
                        }}
                        onClick={() => {
                          navigate("/joblist");
                        }}
                        size="small"
                        key={element}
                      >
                        {element}
                      </Button>
                    ))}
                </Stack>
                <Stack
                  spacing={0}
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"left"}
                >
                  {poster_status == "1" ? (
                    <CheckCircleRounded
                      style={{ fontSize: "12pt", color: "darkgreen" }}
                    />
                  ) : (
                    <CheckCircleRounded
                      style={{ fontSize: "12pt", color: "gray" }}
                    />
                  )}

                  <Typography
                    variant="p"
                    sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                  >
                    {job_poster_name}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                  >
                    ||
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                  >
                    {poster_locat}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <JobDetailsModal
        handleClose={handleClose}
        open={open}
        test={test}
        setOpen={setOpen}
        handleTest={handleTest}
        fetchJobDetails={fetchJobDetails}
        // style={style}
      />
    </Paper>
  );
};

export default Job;
