import * as React from "react";
import Box from "@mui/material/Box";

import { Button, Container, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { KeyboardArrowRight } from "@mui/icons-material";
import Slider from "react-slick";
import { useState } from "react";
import { useEffect } from "react";
import "./HomeSlider.css";
import { useNavigate } from "react-router-dom";
import { serviceApi } from "../../../API/Api";
import ServiceCardItem from "../../../Homepage Components/Trending Services/ServiceCardItem";

const PopularServiceSlider = (props) => {
  const { all, type, route } = props;
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(
      "https://ghorami.com/profile/login/api_service/new_24/servi_home_popular.php"
    )
      .then((res) => res.json())
      .then((result) => {
        setData(result);
      });

    // fetch(typeApiUrl)
    //   .then((res) => res.json())
    //   .then((result) => {
    //     setBookType(result.slice(20, 28));
    //   });
  }, []);
  const settings = {
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    draggable: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ backgroundColor: "#ffffff", paddingY: "15px" }}>
      <Container className="homeSlick">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "0% 0" }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: "13pt" }}>
            {type}
          </Typography>
          <Button
            sx={{
              fontSize: "10pt",
              textTransform: "capitalize",
            }}
            onClick={() => {
              navigate("/" + route);
            }}
          >
            {all}
          </Button>
        </Stack>
        <Divider />
        <Box sx={{ marginTop: "15px" }}>
          <Slider {...settings}>
            {data?.map((element) => (
              <Stack
                direction={"row"}
                justifyContent="center"
                alignItems={"center"}
                key={element.service_refer}
              >
                <Box sx={{ marginX: "10px", marginBottom: "25px" }}>
                  <ServiceCardItem data={element} editOff={true} />
                </Box>
              </Stack>
            ))}
          </Slider>
        </Box>
        {/* <Box sx={{ paddingTop: "40px" }}>
          <Slider {...settings2}>
            {bookType?.map((element) => (
              <Box key={element.type_name_bn}>
                <Button
                  variant="outlined"
                  sx={{ width: { xs: "100%", md: "90%" } }}
                >
                  {element.type_name_bn}
                </Button>
              </Box>
            ))}
          </Slider>
        </Box> */}
      </Container>
    </Box>
  );
};

export default PopularServiceSlider;
